<template>
  <div class="container_margin">
    <!-- <div class="row mt-2">
      <div class="col">
        <b>{{ "Account Info" }} </b>
      </div>
    </div> -->

    <!-- <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 align-self-center">
              <p>{{ "Company Name" }}</p>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 align-self-center">
              <b>: {{ company_name }}</b>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 align-self-center">
              <p>{{ "Website" }}</p>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 align-self-center">
              <b>: {{ website }} </b>
            </div>
          </div>
        </div> -->

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col">
            <h4 class="font-weight-bold">{{ "My Account" }}</h4>
          </div>
        </div>

        <div v-if="extraInfo">
          <div class="row row-detail">
            <div class="col align-self-center">
              <b>{{ "Law Firm Name" }} </b>
            </div>
          </div>

          <div class="row row-detail">
            <div class="col align-self-center">
              <b-form-input
                v-model.trim="extra_info.company_name"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>
            </div>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col align-self-center">
            <b>{{ "Lawyer Name" }} </b>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col align-self-center">
            <b-form-input
              v-model.trim="name"
              autocorrect="off"
              autocomplete="off"
            ></b-form-input>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col align-self-center">
            <b>{{ "Lawyer Email" }} </b>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col align-self-center">
            <b-form-input
              v-model.trim="email"
              autocorrect="off"
              autocomplete="off"
            ></b-form-input>
          </div>
        </div>

        <div v-if="extraInfo">
          <div class="row row-detail">
            <div class="col align-self-center">
              <b>{{ "Firm Address" }} </b>
            </div>
          </div>

          <div class="row row-detail">
            <div class="col align-self-center">
              <!-- <b-form-textarea
              id="textarea"
              v-model="extra_info.address"
              autocorrect="off"
              autocomplete="off"
              rows="3"
              max-rows="6"
            ></b-form-textarea> -->

              <b-form-input
                v-model.trim="extra_info.firm_address_1"
                placeholder="Address Line 1"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>

              <b-form-input
                class="input-address"
                v-model.trim="extra_info.firm_address_2"
                placeholder="Address Line 2"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>

              <b-form-input
                class="input-address"
                v-model.trim="extra_info.firm_address_3"
                placeholder="Address Line 3"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>
            </div>
          </div>
        </div>

        <!-- <div class="col align-self-center">
          <b-form-input
            class="input-address"
            v-model="sender_address_1"
            placeholder="Address Line 1"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Sender Address Name"
          ></b-form-input>

          <b-form-input
            class="input-address"
            v-model="sender_address_2"
            placeholder="Address Line 2"
            autocorrect="off"
            autocomplete="off"
            type="text"
          ></b-form-input>

          <b-form-input
            class="input-address"
            v-model="sender_address_3"
            placeholder="Address Line 3"
            autocorrect="off"
            autocomplete="off"
            type="text"
          ></b-form-input>
        </div> -->

        <div v-if="extraInfo">
          <div class="row row-detail">
            <div class="col align-self-center">
              <b>{{ "Law Firm Contact Number" }} </b>
            </div>
          </div>

          <div class="row row-detail">
            <div class="col align-self-center">
              <b-form-input
                type="text"
                v-model="extra_info.contact_number"
                autocorrect="off"
                autocomplete="off"
              ></b-form-input>
            </div>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col"></div>
          <div class="col-5">
            <button
              @click="saveChanges()"
              class="btn btn-primary float-right"
              id="editButton"
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col">
            <h4 class="font-weight-bold">{{ "My Password" }}</h4>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col align-self-center">
            <b>{{ "Current Password" }} </b>
          </div>
        </div>
        <div class="row row-detail">
          <div class="col align-self-center">
            <b-form-input
              v-model="form.old_password"
              autocorrect="off"
              autocomplete="off"
              type="password"
            ></b-form-input>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col align-self-center">
            <b>{{ "New Password" }} </b>
          </div>
        </div>
        <div class="row row-detail">
          <div class="col align-self-center">
            <b-form-input
              v-model="form.password"
              autocorrect="off"
              autocomplete="off"
              type="password"
            ></b-form-input>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col align-self-center">
            <b>{{ "Confirm Password" }} </b>
          </div>
        </div>
        <div class="row row-detail">
          <div class="col align-self-center">
            <b-form-input
              v-model="form.password_confirmation"
              autocorrect="off"
              autocomplete="off"
              type="password"
            ></b-form-input>
          </div>
        </div>

        <div class="row row-detail">
          <div class="col"></div>
          <div class="col-6">
            <button
              @click="savePassword()"
              class="btn btn-primary float-right"
              id="changePassword"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";
export default {
  data() {
    return {
      extraInfo: true,
      website: "www.example.com",
      name: JSON.parse(localStorage.userInfo).name,
      email: JSON.parse(localStorage.userInfo).email,
      form: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },

      extra_info: {
        contact_number: " ",
        firm_address_1: " ",
        firm_address_2: " ",
        firm_address_3: " ",
        company_name: JSON.parse(localStorage.userInfo).extra_info.company_name,
      },
      isEditing: false,
    };
  },
  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    saveChanges() {
      switch("") {
        case this.extra_info.company_name:
        case this.extra_info.firm_address_1:
        case this.extra_info.contact_number:
          this.$toast.error("Please fill in all fields");
          return;
      }
      switch(null) {
        case this.extra_info.company_name:
        case this.extra_info.firm_address_1:
        case this.extra_info.contact_number:
          this.$toast.error("Please fill in all fields");
          return;
      }

      this.extra_info.firm_address_2 = this.extra_info.firm_address_2 == undefined
        ? "" : this.extra_info.firm_address_2;
      this.extra_info.firm_address_3 = this.extra_info.firm_address_3 == undefined
        ? "" : this.extra_info.firm_address_3;

      Auth.updateProfile({
        name: this.name,
        email: this.email,
        extra_info: {
          company_name: this.extra_info.company_name,
          contact_number: this.extra_info.contact_number,
          address: [
            this.extra_info.firm_address_1,
            this.extra_info.firm_address_2,
            this.extra_info.firm_address_3,
          ].join("\n"),
        },
      })
        .then((response) => {
          //
          this.$toast.success(response.data.message);

          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savePassword() {
      Auth.changePassword(this.form)
        .then((response) => {
          this.$toast.success(response.data.message);

          this.$bvModal.hide("modal-center-change-password");
          this.form = {
            old_password: "",
            password: "",
            password_confirmation: "",
          };

          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    if (
      JSON.parse(localStorage.userInfo).extra_info !== null &&
      JSON.parse(localStorage.userInfo).extra_info.length !== 0
    ) {
      const userInfo = JSON.parse(localStorage.userInfo);
      const addressParts = userInfo.extra_info.address?.split("\n") ?? ['','',''];

      this.extra_info.contact_number = userInfo.extra_info.contact_number;
      this.extra_info.firm_address_1 = addressParts[0];
      this.extra_info.firm_address_2 = addressParts[1];
      this.extra_info.firm_address_3 = addressParts[2];
      this.extra_info.company_name = userInfo.extra_info.company_name;

      console.log(this.extra_info);
    }
  },
};
</script>

<style scoped>
.container_margin {
  padding: 2rem;
}

.row-detail {
  margin-top: 1rem;
}

/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: 500px;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

#editButton {
  /* padding-top: 2px !important;
    padding-bottom: 50px !important; */
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.input-address {
  margin: 1rem 0rem;
}

@media only screen and (max-width: 1024px) {
  #changePassword {
    padding-top: 2px !important;
    padding-bottom: 50px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  #changePassword {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 375px) {
  #changePassword {
    padding-top: 5px !important;
    padding-bottom: 53px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
</style>
